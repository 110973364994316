<template>
  <v-card-text>
    <div class="d-flex align-content-center mb-1">
      <v-icon small v-bind:color="status.getColor()">{{ status.getIcon() }}</v-icon>
      <span class="ml-1 black--text">{{ status.getMessage() }}</span>
    </div>
    <v-list class="pa-0">
      <v-list-item v-for="assessment in assessmentStatuses" :key="assessment.key" class="pa-0">
        <v-list-item-icon class="mr-1">
          <v-icon small v-bind:color="assessment.getColor()">
            mdi-circle
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ assessment.getMessage() }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
</template>

<script>
export default {
  name: 'ContentAnalysisSidebarSuggestions',
  computed: {
    status: {
      get () {
        return this.$store.getters.getStatus
      }
    },
    assessmentStatuses: {
      get () {
        return this.$store.getters.getSortedAssessmentStatuses
      }
    }
  }
}
</script>

<style scoped>
  .v-list-item__icon {
    min-width: unset;
  }
</style>
