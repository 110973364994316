import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import HeaderFirstElementAssessmentStatusGood
  from '@/services/content-analysis/assessment/header-first-element-assessment/HeaderFirstElementAssessmentStatusGood'
import HeaderFirstElementAssessmentStatusBad
  from '@/services/content-analysis/assessment/header-first-element-assessment/HeaderFirstElementAssessmentStatusBad'

export default class HeaderFirstElementAssessment extends Assessment {
  assess (): void {
    if (store.getters.getFirstElement.tagName === 'H1') {
      this.setAssessmentStatus(new HeaderFirstElementAssessmentStatusGood())
    }
    else {
      this.setAssessmentStatus(new HeaderFirstElementAssessmentStatusBad())
    }
  }
}
