import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import InternalLinkAssessmentStatusMissing
  from '@/services/content-analysis/assessment/internal-link-assessment/InternalLinkAssessmentStatusMissing'
import InternalLinkAssessmentStatusGood
  from '@/services/content-analysis/assessment/internal-link-assessment/InternalLinkAssessmentStatusGood'

export default class InternalLinkAssessment extends Assessment {
  public static readonly MINIMUM_LINK_COUNT: number = 0

  assess (): void {
    const internalLinkCount = store.getters.getInternalLinkCount

    if (internalLinkCount === InternalLinkAssessment.MINIMUM_LINK_COUNT) {
      this.setAssessmentStatus(new InternalLinkAssessmentStatusMissing())
    }
    else if (internalLinkCount > InternalLinkAssessment.MINIMUM_LINK_COUNT) {
      this.setAssessmentStatus(new InternalLinkAssessmentStatusGood())
    }
  }
}
