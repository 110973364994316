import { Module } from 'vuex'

const DashboardModule: Module<any, any> = {
  state: {
    drawer: null
  },
  getters: {
    getDrawer: state => { return state.drawer }
  },
  mutations: {
    toggle: state => { state.drawer = !state.drawer },
    setDrawer: (state, drawer) => { state.drawer = drawer }
  },
  actions: {

  }
}

export default DashboardModule
