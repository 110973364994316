import Text from '@/services/content-analysis/content/Text'

export default class HeaderSibling extends Text<HTMLElement> {
  constructor (headerElement: HTMLElement) {
    super()

    this.setHeaderSiblings(headerElement)
  }

  private setHeaderSiblings (headerElement: HTMLElement): void {
    const sibling = headerElement.nextSibling as HTMLElement

    if (sibling !== null && sibling.tagName === 'P') {
      this.elements.push(sibling)

      this.setHeaderSiblings(sibling)
    }
  }
}
