import IStatus from '@/services/content-analysis/status/IStatus'

export default class BadStatus implements IStatus {
  getColor (): string {
    return 'red'
  }

  getIcon (): string {
    return 'mdi-alert-circle'
  }

  getMessage (): string {
    return 'Oh oh, there\'s some suggestions.'
  }
}
