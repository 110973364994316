import Vue from 'vue'
import Vuetify from 'vuetify'
// @ts-ignore
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#26ae7d',
        secondary: '#22b8d0',
        accent: '#f9de51'
      }
    }
  }
})

Vue.use(Vuetify)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

export default vuetify
