import AssessmentStatus from '@/services/content-analysis/assessment/AssessmentStatus'
import { Status } from '@/services/content-analysis/assessment/Status'
import store from '@/store'
import KeywordMatches from '@/services/content-analysis/content/KeywordMatches'

export default class KeywordDensityAssessmentStatusMaximum extends AssessmentStatus {
  constructor (keywordMatches: KeywordMatches) {
    super(Status.Ok, `The keyword density for keyword "${keywordMatches.getKeyword()}" is ${keywordMatches.getDensityPercentageString()}%, this is nearing the maximum.`)
  }
}
