<template>
  <!-- Start Content -->
  <router-view></router-view>
  <!-- End Content -->
</template>

<script>
export default {
  name: 'Empty'
}
</script>

<style scoped>

</style>
