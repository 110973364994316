import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import ParagraphLengthAssessmentStatusLong
  from '@/services/content-analysis/assessment/paragraph-length-assessment/ParagraphLengthAssessmentStatusLong'

export default class ParagraphLengthAssessment extends Assessment {
  public static readonly MAXIMUM_PARAGRAPH_LENGTH = 150

  public assess (): void {
    const paragraphs = store.getters.getParagraphs

    this.resetAssessmentStatus()

    for (let paragraph of paragraphs) {
      if (paragraph.length > ParagraphLengthAssessment.MAXIMUM_PARAGRAPH_LENGTH) {
        this.setAssessmentStatus(new ParagraphLengthAssessmentStatusLong())
        break
      }
    }
  }
}
