<template>
  <div>
    <div class="d-flex align-content-center mb-1">
      <v-icon small color="grey darken-4">mdi-file-word-box</v-icon>
      <span class="ml-1">Keywords</span>
    </div>
    <v-text-field
      v-model="keywords"
      label="Focus keywords"
      placeholder="marketing tools"
      outlined
      clearable
      counter
      :counter-value="keywords => keywords ? keywords.split(',').length : 0"
      hide-details="auto"
    ></v-text-field>
  </div>
</template>

<script>
import ContentAnalysis from '@/services/content-analysis'

export default {
  name: 'ContentAnalysisSidebarKeywords',
  computed: {
    keywords: {
      get () {
        return this.$store.getters.getKeyword
      },
      set (value) {
        this.$store.commit('setKeywords', value ? value.split(',') : [])
      }
    }
  },
  watch: {
    keywords: ContentAnalysis.analyse
  }
}
</script>
