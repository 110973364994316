










import FirebaseUi from '@/components/auth/FirebaseUI.vue'

export default {
  name: 'login',
  components: { FirebaseUi }
}
