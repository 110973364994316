











import Editor from '@/components/content-analysis/ContentAnalysisEditor.vue'
import Sidebar from '@/components/content-analysis/ContentAnalysisSidebar.vue'
import ContentAnalysis from '@/services/content-analysis'

export default {
  name: 'ContentAnalysis',
  components: { Editor, Sidebar },

  mounted () {
    ContentAnalysis.analyse()
  }
}
