import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import FocusKeywordFirstParagraphAssessmentStatusBad
  from '@/services/content-analysis/assessment/focus-keyword-first-paragraph-assessment/FocusKeywordFirstParagraphAssessmentStatusBad'
import FocusKeywordFirstParagraphAssessmentStatusGood
  from '@/services/content-analysis/assessment/focus-keyword-first-paragraph-assessment/FocusKeywordFirstParagraphAssessmentStatusGood'

export default class FocusKeywordFirstParagraphAssessment extends Assessment {
  assess (): void {
    const focusKeywordMatches = store.getters.getKeywordsInFirstParagraph.length

    if (focusKeywordMatches === 0 || focusKeywordMatches !== store.getters.getKeywords.length) {
      this.setAssessmentStatus(new FocusKeywordFirstParagraphAssessmentStatusBad())
    }
    else {
      this.setAssessmentStatus(new FocusKeywordFirstParagraphAssessmentStatusGood())
    }
  }
}
