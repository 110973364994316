import AssessmentStatus from '@/services/content-analysis/assessment/AssessmentStatus'
import { Status } from '@/services/content-analysis/assessment/Status'
import store from '@/store'
import ContentLengthAssessment from '@/services/content-analysis/assessment/content-length-assessment/index'

export default class ContentLengthAssessmentStatusGood extends AssessmentStatus {
  constructor () {
    super(Status.Good, `The text contains ${store.getters.getWordCount} words. This is more than or equal to the recommended minimum of ${ContentLengthAssessment.MINIMUM_WORD_COUNT} words. Add more content that is relevant for the topic.`)
  }
}
