<template>
  <tiptap-vuetify
    v-model="content"
    :extensions="extensions"
  />
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, History } from 'tiptap-vuetify'
import ContentAnalysis from '@/services/content-analysis'

export default {
  name: 'ContentAnalysisEditor',
  components: { TiptapVuetify },
  data: () => ({
    extensions: [
      Bold,
      Italic,
      Strike,
      Underline,
      [Heading, {
        options: {
          levels: [1, 2, 3, 4, 5, 6]
        }
      }],
      Paragraph,
      BulletList,
      OrderedList,
      ListItem,
      Link,
      History
    ]
  }),
  computed: {
    content: {
      get () {
        return this.$store.getters.getContent.toString()
      },
      set (value) {
        this.$store.commit('setContent', value)
      }
    }
  },
  watch: {
    content: ContentAnalysis.analyse
  }
}
</script>
