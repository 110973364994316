import store from '@/store'

export default class ContentParser {
  private static readonly WORD_REGEX: string = '(?:[\\W]+(?=\\w))'
  private static readonly SENTENCE_REGEX: string = '(?:[.?!\\n]\\s*(?=\\w))'

  private readonly parser: DOMParser
  private static readonly DOCUMENT_TYPE: SupportedType = 'text/html'
  private readonly document: Document

  public static readonly HEADER_TAGS: string[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

  constructor (html: string) {
    this.parser = new DOMParser()
    this.document = this.parser.parseFromString(html, ContentParser.DOCUMENT_TYPE)
  }

  public static GET_WORD_REGEX (): RegExp {
    return new RegExp(ContentParser.WORD_REGEX, 'g')
  }

  public static GET_SENTENCE_REGEX (): RegExp {
    return new RegExp(ContentParser.SENTENCE_REGEX, 'g')
  }

  public getDocument (): Document {
    return this.document
  }

  public toString (): string {
    return (new XMLSerializer()).serializeToString(this.getBody())
  }

  public getBody (): HTMLElement {
    return this.document.body
  }

  public getInternalLinks (): NodeListOf<HTMLElement> {
    return this.document.querySelectorAll(`a[href*='${store.getters.getDomain}']`)
  }

  public getExternalLinks (): NodeListOf<HTMLElement> {
    return this.document.querySelectorAll(`a:not([href*='${store.getters.getDomain}'])`)
  }

  public getFirstElement (): Element {
    return this.document.querySelector('body *:first-child') as Element
  }
}
