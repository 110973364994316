import IAssessment from '@/services/content-analysis/assessment/IAssessment'
import IAssessmentStatus from '@/services/content-analysis/assessment/IAssessmentStatus'

export default class Assessment implements IAssessment {
  private assessmentStatuses: IAssessmentStatus[] = []

  public assess (): void {
    throw new Error('assess() is not implemented!')
  }

  public getAssessmentStatuses (): IAssessmentStatus[] {
    return this.assessmentStatuses
  }

  public getFirstAssessmentStatus (): IAssessmentStatus {
    if (this.assessmentStatuses.length > 0) {
      return this.assessmentStatuses[0]
    }
    else {
      throw new Error('Assessment status is empty!')
    }
  }

  protected setAssessmentStatus (assessmentStatus: IAssessmentStatus) {
    this.assessmentStatuses = [assessmentStatus]
  }

  protected addAssessmentStatus (assessmentStatus: IAssessmentStatus) {
    if (this.assessmentStatuses === null) {
      this.assessmentStatuses = []
    }

    this.assessmentStatuses.push(assessmentStatus)
  }

  protected resetAssessmentStatus () {
    this.assessmentStatuses = []
  }
}
