<template>
  <div>
    <div class="d-flex align-content-center mb-1">
      <v-icon small color="grey darken-4">mdi-code-tags</v-icon>
      <span class="ml-1">Meta</span>
    </div>
    <v-text-field
      v-model="title"
      label="Meta Title"
      placeholder="PositiveDigits - Marketing Tools"
      outlined
      clearable
      v-bind:counter="metaTitleCounter"
      hide-details="auto"
      class="mb-2"
    ></v-text-field>
    <v-textarea
      v-model="description"
      label="Meta Description"
      placeholder="PositiveDigits Marketing Tools"
      outlined
      clearable
      v-bind:counter="metaDescriptionCounter"
      hide-details="auto"
    ></v-textarea>
  </div>
</template>

<script>
import ContentAnalysis from '@/services/content-analysis'
import MetaTitleLengthAssessment from '@/services/content-analysis/assessment/meta-title-length-assessment'
import MetaDescriptionLengthAssessment from '@/services/content-analysis/assessment/meta-description-length-assessment'

export default {
  name: 'ContentAnalysisSidebarMeta',
  data: () => ({
    metaTitleCounter: MetaTitleLengthAssessment.MAXIMUM_CHARACTER_COUNT,
    metaDescriptionCounter: MetaDescriptionLengthAssessment.MAXIMUM_CHARACTER_COUNT
  }),
  computed: {
    title: {
      get () {
        return this.$store.getters.getMetaTitle
      },
      set (value) {
        this.$store.commit('setMetaTitle', value)
      }
    },
    description: {
      get () {
        return this.$store.getters.getMetaDescription
      },
      set (value) {
        this.$store.commit('setMetaDescription', value)
      }
    }
  },
  watch: {
    title: ContentAnalysis.analyse,
    description: ContentAnalysis.analyse
  }
}
</script>
