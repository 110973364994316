import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import MetaDescriptionLengthAssessmentStatusMissing
  from '@/services/content-analysis/assessment/meta-description-length-assessment/MetaDescriptionLengthAssessmentStatusMissing'
import MetaDescriptionLengthAssessmentStatusMedium
  from '@/services/content-analysis/assessment/meta-description-length-assessment/MetaDescriptionLengthAssessmentStatusMedium'
import MetaDescriptionLengthAssessmentStatusGood
  from '@/services/content-analysis/assessment/meta-description-length-assessment/MetaDescriptionLengthAssessmentStatusGood'
import MetaDescriptionLengthAssessmentStatusLong
  from '@/services/content-analysis/assessment/meta-description-length-assessment/MetaDescriptionLengthAssessmentStatusLong'

export default class MetaDescriptionLengthAssessment extends Assessment {
  public static readonly MINIMUM_CHARACTER_COUNT: number = 0
  public static readonly TARGET_CHARACTER_COUNT: number = 120
  public static readonly MAXIMUM_CHARACTER_COUNT: number = 155

  assess (): void {
    const metaDescriptionLength: number = store.getters.getMetaDescription.length

    if (metaDescriptionLength === MetaDescriptionLengthAssessment.MINIMUM_CHARACTER_COUNT) {
      this.setAssessmentStatus(new MetaDescriptionLengthAssessmentStatusMissing())
    }
    else if (metaDescriptionLength > MetaDescriptionLengthAssessment.MINIMUM_CHARACTER_COUNT && metaDescriptionLength < MetaDescriptionLengthAssessment.TARGET_CHARACTER_COUNT) {
      this.setAssessmentStatus(new MetaDescriptionLengthAssessmentStatusMedium())
    }
    else if (metaDescriptionLength > MetaDescriptionLengthAssessment.TARGET_CHARACTER_COUNT && metaDescriptionLength < MetaDescriptionLengthAssessment.MAXIMUM_CHARACTER_COUNT) {
      this.setAssessmentStatus(new MetaDescriptionLengthAssessmentStatusGood())
    }
    else if (metaDescriptionLength > MetaDescriptionLengthAssessment.MAXIMUM_CHARACTER_COUNT) {
      this.setAssessmentStatus(new MetaDescriptionLengthAssessmentStatusLong())
    }
  }
}
