import IStatus from '@/services/content-analysis/status/IStatus'

export default class GoodStatus implements IStatus {
  getColor (): string {
    return 'green'
  }

  getIcon (): string {
    return 'mdi-check-circle'
  }

  getMessage (): string {
    return 'Everything\'s good!'
  }
}
