import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import FocusKeywordsAssessmentStatusMissing
  from '@/services/content-analysis/assessment/focus-keywords-assessment/FocusKeywordsAssessmentStatusMissing'

export default class FocusKeywordsAssessment extends Assessment {
  assess (): void {
    const keywords = store.getters.getKeywords

    if (keywords.length < 1) {
      this.setAssessmentStatus(new FocusKeywordsAssessmentStatusMissing())
    }
    else {
      this.resetAssessmentStatus()
    }
  }
}
