<template>
  <div>
    <nav-bar></nav-bar>
    <side-menu></side-menu>
    <v-content>
      <!-- Start Content -->
      <v-container fluid>
        <router-view></router-view>
      </v-container>
      <!-- End Content -->
    </v-content>
  </div>
</template>

<script>
import NavBar from '@/components/dashboard/NavBar'
import SideMenu from '@/components/dashboard/SideMenu'

export default {
  name: 'Dashboard',
  components: { NavBar, SideMenu }
}
</script>

<style scoped>

</style>
