import store from '@/store'
import ContentParser from '@/services/content-analysis/content/ContentParser'
import Headers from '@/services/content-analysis/content/Headers'
import Paragraphs from '@/services/content-analysis/content/Paragraphs'
import Text from '@/services/content-analysis/content/Text'
import KeywordMatches from '@/services/content-analysis/content/KeywordMatches'

export default class Content extends Text<HTMLElement> {
  private readonly contentParser: ContentParser
  private readonly headers: Headers
  private readonly paragraphs: Paragraphs

  constructor (html: string) {
    super()

    this.contentParser = new ContentParser(html)
    this.elements = Array.from(this.contentParser.getBody().children) as HTMLElement[]
    this.headers = new Headers(this.contentParser)
    this.paragraphs = new Paragraphs(this.contentParser)
  }

  getContentParser (): ContentParser {
    return this.contentParser
  }

  getHeaders (): Headers {
    return this.headers
  }

  getParagraphs (): Paragraphs {
    return this.paragraphs
  }

  getKeywordMatches (): KeywordMatches[] {
    return store.getters.getKeywords.map((k: string) => new KeywordMatches(k, this))
  }

  getFirstElement (): Element {
    return this.contentParser.getFirstElement()
  }

  toString (): string {
    return this.contentParser.toString()
  }
}
