<template>
  <v-app-bar app color="primary" dark>
    <!-- Start Left App Bar -->
    <v-app-bar-nav-icon @click="this.toggle" class="hidden-lg-and-up"></v-app-bar-nav-icon>
    <!-- End Left App Bar -->

    <v-spacer></v-spacer>

    <!-- Start Right App Bar -->
    <!-- Start Context Menu -->
    <v-menu bottom left offset-y>
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on">
          <v-avatar size="36">
            <img v-bind:src="avatar" v-if="avatar" alt="avatar" referrerpolicy="no-referrer" >
            <v-icon v-else>mdi-account</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="logout()">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- End Context Menu -->

    <!-- End Right App Bar -->
  </v-app-bar>
</template>

<script>
import firebase from 'firebase/compat'
import { mapMutations } from 'vuex'

export default {
  name: 'NavBar',
  data: () => ({
    avatar: firebase.auth().currentUser.photoURL
  }),
  methods: {
    logout: function () {
      firebase.auth().signOut().then(() => {
        this.$router.push('/dashboard/login')
      })
    },
    ...mapMutations([
      'toggle'
    ])
  }
}
</script>
