import Text from '@/services/content-analysis/content/Text'
import ContentParser from '@/services/content-analysis/content/ContentParser'
import Sentences from '@/services/content-analysis/content/Sentences'
import Italics from '@/services/content-analysis/content/Italics'

export default class Paragraphs extends Text<HTMLElement> {
  private readonly sentences: Sentences
  private readonly italics: Italics

  constructor (contentParser: ContentParser) {
    super()

    this.elements = Array.from(contentParser.getDocument().querySelectorAll('p'))
    this.sentences = new Sentences(this.elements)
    this.italics = new Italics(this.elements)
  }

  public toString (): string[] {
    return this.elements.map((e: HTMLElement) => e.textContent!.toLowerCase())
  }

  public getSentences (): Sentences {
    return this.sentences
  }

  public getItalics (): Italics {
    return this.italics
  }
}
