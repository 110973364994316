import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import KeywordDensityAssessmentStatusLow
  from '@/services/content-analysis/assessment/keyword-density-assessment/KeywordDensityAssessmentStatusLow'
import KeywordDensityAssessmentStatusGood
  from '@/services/content-analysis/assessment/keyword-density-assessment/KeywordDensityAssessmentStatusGood'
import KeywordDensityAssessmentStatusMaximum
  from '@/services/content-analysis/assessment/keyword-density-assessment/KeywordDensityAssessmentStatusMaximum'
import KeywordDensityAssessmentStatusOverMaximum
  from '@/services/content-analysis/assessment/keyword-density-assessment/KeywordDensityAssessmentStatusOverMaximum'

export default class KeywordDensityAssessment extends Assessment {
  public static readonly MINIMUM_DENSITY_PERCENTAGE: number = 0.5
  public static readonly MAXIMUM_DENSITY_PERCENTAGE: number = 3.5
  public static readonly OVER_MAXIMUM_DENSITY_PERCENTAGE: number = 4

  assess (): void {
    this.resetAssessmentStatus()

    for (const keywordMatches of store.getters.getKeywordMatches) {
      const keywordDensityPercentage: number = keywordMatches.getDensityPercentage()

      if (keywordDensityPercentage < KeywordDensityAssessment.MINIMUM_DENSITY_PERCENTAGE) {
        this.addAssessmentStatus(new KeywordDensityAssessmentStatusLow(keywordMatches))
      }
      else if (this.keywordDensityBetween(keywordDensityPercentage, KeywordDensityAssessment.MINIMUM_DENSITY_PERCENTAGE, KeywordDensityAssessment.MAXIMUM_DENSITY_PERCENTAGE)) {
        this.addAssessmentStatus(new KeywordDensityAssessmentStatusGood(keywordMatches))
      }
      else if (this.keywordDensityBetween(keywordDensityPercentage, KeywordDensityAssessment.MAXIMUM_DENSITY_PERCENTAGE, KeywordDensityAssessment.OVER_MAXIMUM_DENSITY_PERCENTAGE)) {
        this.addAssessmentStatus(new KeywordDensityAssessmentStatusMaximum(keywordMatches))
      }
      else if (keywordDensityPercentage > KeywordDensityAssessment.OVER_MAXIMUM_DENSITY_PERCENTAGE) {
        this.addAssessmentStatus(new KeywordDensityAssessmentStatusOverMaximum(keywordMatches))
      }
    }
  }

  private keywordDensityBetween (percentage: number, minimum: number, maximum: number) {
    return percentage > minimum && percentage < maximum
  }
}
