import { Module } from 'vuex'
import GoodStatus from '@/services/content-analysis/status/GoodStatus'
import IAssessment from '@/services/content-analysis/assessment/IAssessment'
import { Status } from '@/services/content-analysis/assessment/Status'
import FocusKeywordsAssessment from '@/services/content-analysis/assessment/focus-keywords-assessment'
import MetaDescriptionLengthAssessment from '@/services/content-analysis/assessment/meta-description-length-assessment'
import MetaTitleLengthAssessment from '@/services/content-analysis/assessment/meta-title-length-assessment'
import MetaTitleAssessment from '@/services/content-analysis/assessment/meta-title-assessment'
import InternalLinkAssessment from '@/services/content-analysis/assessment/internal-link-assessment'
import ExternalLinkAssessment from '@/services/content-analysis/assessment/external-link-assessment'
import KeywordDensityAssessment from '@/services/content-analysis/assessment/keyword-density-assessment'
import ContentLengthAssessment from '@/services/content-analysis/assessment/content-length-assessment'
import FocusKeywordFirstParagraphAssessment
  from '@/services/content-analysis/assessment/focus-keyword-first-paragraph-assessment'
import ParagraphLengthAssessment from '@/services/content-analysis/assessment/paragraph-length-assessment'
import SentenceLengthAssessment from '@/services/content-analysis/assessment/sentence-length-assessment'
import HeaderSequenceAssessment from '@/services/content-analysis/assessment/header-sequence-assessment'
import HeaderCountAssessment from '@/services/content-analysis/assessment/header-count-assessment'
import ContentModule from '@/store/modules/content-analysis-module/ContentModule'
import KeywordsModule from '@/store/modules/content-analysis-module/KeywordsModule'
import MetaModule from '@/store/modules/content-analysis-module/MetaModule'
import UrlModule from '@/store/modules/content-analysis-module/UrlModule'
import LinkModule from '@/store/modules/content-analysis-module/LinkModule'
import ItalicKeywordFirstParagraphAssessment
  from '@/services/content-analysis/assessment/italic-keyword-first-paragraph-assessment'
import HeaderFirstElementAssessment from '@/services/content-analysis/assessment/header-first-element-assessment'
import IAssessmentStatus from '@/services/content-analysis/assessment/IAssessmentStatus'
import HeaderLevelElementRightOrderAssessment
  from '@/services/content-analysis/assessment/header-level-element-right-order-assessment'

const ContentAnalysis: Module<any, any> = {
  state: {
    status: new GoodStatus(),
    assessments: [
      new FocusKeywordsAssessment(),
      new MetaDescriptionLengthAssessment(),
      new MetaTitleLengthAssessment(),
      new MetaTitleAssessment(),
      new InternalLinkAssessment(),
      new ExternalLinkAssessment(),
      new KeywordDensityAssessment(),
      new ContentLengthAssessment(),
      new FocusKeywordFirstParagraphAssessment(),
      new ParagraphLengthAssessment(),
      new SentenceLengthAssessment(),
      new HeaderCountAssessment(),
      new HeaderSequenceAssessment(),
      new ItalicKeywordFirstParagraphAssessment(),
      new HeaderFirstElementAssessment(),
      new HeaderLevelElementRightOrderAssessment()
    ]
  },
  getters: {
    getStatus: state => { return state.status },
    getAssessments: state => { return state.assessments },
    getAssessmentStatuses: (state, getters) => {
      return getters.getAssessments
        .filter((a: IAssessment) => a.getAssessmentStatuses().length > 0)
        .map((a: IAssessment) => a.getAssessmentStatuses()).flat()
    },
    getSortedAssessmentStatuses: (state, getters) => {
      return getters.getAssessmentStatuses.sort((a: IAssessmentStatus, b: IAssessmentStatus) => -(a.getStatus() - b.getStatus()))
    },
    hasAssessmentOfStatus: (state, getters) => (status: Status) => {
      return getters.getAssessmentStatuses.filter((s: IAssessmentStatus) => s.isStatus(status)).length > 0
    }
  },
  mutations: {
    setStatus: (state, status) => { state.status = status }
  },
  actions: {
  },
  modules: {
    Content: ContentModule,
    Keywords: KeywordsModule,
    Meta: MetaModule,
    Url: UrlModule,
    Link: LinkModule
  }
}

export default ContentAnalysis
