import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import { Status } from '@/services/content-analysis/assessment/Status'
import SentenceLengthAssessmentStatus
  from '@/services/content-analysis/assessment/sentence-length-assessment/SentenceLengthAssessmentStatus'

export default class SentenceLengthAssessment extends Assessment {
  public static readonly MAXIMUM_SENTENCE_LENGTH: number = 20
  public static readonly MAXIMUM_SENTENCE_LENGTH_PERCENTAGE: number = 25

  assess (): void {
    const sentences = store.getters.getSentences
    let lengthySentencesCount: number = 0

    this.resetAssessmentStatus()

    if (sentences.length < 1) return

    for (let sentence of sentences) {
      if (sentence.length > SentenceLengthAssessment.MAXIMUM_SENTENCE_LENGTH) {
        ++lengthySentencesCount
      }
    }

    const lengthySentencePercentage = (lengthySentencesCount * 100) / sentences.length

    if (lengthySentencePercentage > SentenceLengthAssessment.MAXIMUM_SENTENCE_LENGTH_PERCENTAGE) {
      this.setAssessmentStatus(new SentenceLengthAssessmentStatus(Status.Bad, lengthySentencePercentage))
    }
    else {
      this.setAssessmentStatus(new SentenceLengthAssessmentStatus(Status.Good, lengthySentencePercentage))
    }
  }
}
