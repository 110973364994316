import store from '@/store'
import BadStatus from '@/services/content-analysis/status/BadStatus'
import OkStatus from '@/services/content-analysis/status/OkStatus'
import GoodStatus from '@/services/content-analysis/status/GoodStatus'
import { Status } from '@/services/content-analysis/assessment/Status'

export default class ContentAnalysis {
  public static analyse (): void {
    for (const assessment of store.getters.getAssessments) {
      assessment.assess()
    }

    if (store.getters.hasAssessmentOfStatus(Status.Bad)) {
      store.commit('setStatus', new BadStatus())
    }
    else if (store.getters.hasAssessmentOfStatus(Status.Ok)) {
      store.commit('setStatus', new OkStatus())
    }
    else {
      store.commit('setStatus', new GoodStatus())
    }
  }
}
