import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import ParagraphLengthAssessment from '@/services/content-analysis/assessment/paragraph-length-assessment'
import HeaderSequenceAssessmentStatusGood
  from '@/services/content-analysis/assessment/header-sequence-assessment/HeaderSequenceAssessmentStatusGood'
import HeaderSequenceAssessmentStatusBad
  from '@/services/content-analysis/assessment/header-sequence-assessment/HeaderSequenceAssessmentStatusBad'

export default class HeaderSequenceAssessment extends Assessment {
  public static readonly MAXIMUM_SEQUENCED_PARAGRAPH_LENGTH: number = ParagraphLengthAssessment.MAXIMUM_PARAGRAPH_LENGTH * 2

  public assess (): void {
    const headerSiblings = store.getters.getHeaders.getSiblings()
    let lengthyParagraphCount: number = 0

    for (let sibling of headerSiblings) {
      if (sibling.getTotalWords().length > HeaderSequenceAssessment.MAXIMUM_SEQUENCED_PARAGRAPH_LENGTH) {
        ++lengthyParagraphCount
      }
    }

    if (headerSiblings.length < 1) {
      this.resetAssessmentStatus()
    }
    else if (lengthyParagraphCount === 0) {
      this.setAssessmentStatus(new HeaderSequenceAssessmentStatusGood())
    }
    else {
      this.setAssessmentStatus(new HeaderSequenceAssessmentStatusBad(lengthyParagraphCount))
    }
  }
}
