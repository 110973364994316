import Text from '@/services/content-analysis/content/Text'

export default class Italics extends Text<string[]> {
  constructor (paragraphs: HTMLElement[]) {
    super()

    for (let paragraph of paragraphs) {
      const italics = Array.from<HTMLElement>(paragraph.querySelectorAll('em')).map((e: HTMLElement) => e.textContent!.trim().toLowerCase())

      this.elements.push(italics)
    }
  }

  public first (): string[] {
    return this.elements.length > 0 ? this.elements[0] : []
  }
}
