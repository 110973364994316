import { Module } from 'vuex'

const MetaModule: Module<any, any> = {
  state: {
    title: '',
    description: ''
  },
  getters: {
    getMetaTitle: state => { return state.title },
    getMetaDescription: state => { return state.description }
  },
  mutations: {
    setMetaTitle: (state, title) => { state.title = title || '' },
    setMetaDescription: (state, description) => { state.description = description || '' }
  },
  actions: {}
}

export default MetaModule
