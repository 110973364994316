<template>
  <div>
    <div class="d-flex align-content-center mb-1">
      <v-icon small>mdi-application</v-icon>
      <span class="ml-1">Page</span>
    </div>
    <v-text-field
      v-model="url"
      label="Page URL"
      placeholder="https://positivedigits.tools/"
      outlined
      clearable
      hide-details="auto"
      class="mb-2"
    ></v-text-field>
  </div>
</template>

<script>
import ContentAnalysis from '@/services/content-analysis'

export default {
  name: 'ContentAnalysisSidebarPage',
  computed: {
    url: {
      get () {
        return this.$store.getters.getUrl
      },
      set (value) {
        this.$store.commit('setUrl', value)
      }
    }
  },
  watch: {
    url: ContentAnalysis.analyse
  }
}
</script>
