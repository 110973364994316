import { Module } from 'vuex'

const LinkModule: Module<any, any> = {
  state: {},
  getters: {
    getLinkCount: (state, getters, rootState, rootGetters) => { return rootGetters.getContentDocument.querySelectorAll('a').length },
    getInternalLinkCount: (state, getters, rootState, rootGetters) => {
      return rootGetters.getContentParser.getInternalLinks().length
    },
    getExternalLinkCount: (state, getters, rootState, rootGetters) => {
      return rootGetters.getContentParser.getExternalLinks().length
    }
  },
  mutations: {},
  actions: {}
}

export default LinkModule
