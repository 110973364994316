import IStatus from '@/services/content-analysis/status/IStatus'

export default class OkStatus implements IStatus {
  getColor (): string {
    return 'orange'
  }

  getIcon (): string {
    return 'mdi-alert'
  }

  getMessage (): string {
    return 'Almost! However, there\'s some suggestions.'
  }
}
