import IAssessmentStatus from '@/services/content-analysis/assessment/IAssessmentStatus'
import { Status } from '@/services/content-analysis/assessment/Status'

export default class AssessmentStatus implements IAssessmentStatus {
  private readonly status: Status
  private readonly message: string

  private readonly statusColors: string[] = [
    'green',
    'orange',
    'red'
  ]

  constructor (status: Status, message: string) {
    this.status = status
    this.message = message
  }

  getStatus (): Status {
    return this.status
  }

  isStatus (status: Status): boolean {
    return this.status === status
  }

  getColor (): string {
    return this.statusColors[this.status]
  }

  getMessage (): string {
    return this.message
  }
}
