import { Module } from 'vuex'
import Content from '@/services/content-analysis/content/index'

const ContentModule: Module<any, any> = {
  state: {
    content: new Content(
      '<h1>Content Analysis</h1>' +
            '<p>Welcome to the Content Analysis editor! Here are a few things we\'d like you to be aware of before you start:</p>' +
            '<ul>' +
              '<li>Formatting your text matters! Use the buttons above to apply headings and styles to your text.</li>' +
              '<li>Your work <strong>is not saved</strong>! Make sure to copy your text from a external document. Refreshing the page restores this message.</li>' +
              '<li>The assessments on the right-hand side run in real-time, as soon as you type. Try entering the focus keyword "Content Analysis" and see the assessment statuses change!</li>' +
              '<li>Multiple keywords are supported. Separate these using a comma, e.g. "Content Analysis, Editor".</li>' +
            '</ul>' +
            '<p>Good luck!</p>'
    )
  },
  getters: {
    getContent: state => { return state.content },
    getContentParser: state => { return state.content.getContentParser() },
    getWordCount: state => { return state.content.getWordCount() },
    getSentences: state => { return state.content.getParagraphs().getSentences().getWords() },
    getParagraphs: state => { return state.content.getParagraphs().getWords() },
    getFirstElement: state => { return state.content.getFirstElement() },
    getHeaders: state => { return state.content.getHeaders() },
    getFirstItalic: state => { return state.content.getParagraphs().getItalics().first() }
  },
  mutations: {
    setContent: (state, content) => { state.content = new Content(content) }
  },
  actions: {}
}

export default ContentModule
