<template>
  <v-card>
    <v-card-text>
      <sidebar-page></sidebar-page>
      <sidebar-keywords></sidebar-keywords>
      <sidebar-meta></sidebar-meta>
    </v-card-text>
    <v-divider class="mx-4"></v-divider>
    <sidebar-suggestions></sidebar-suggestions>
  </v-card>
</template>

<script>
import SidebarKeywords from '@/components/content-analysis/sidebar/ContentAnalysisSidebarKeywords.vue'
import SidebarMeta from '@/components/content-analysis/sidebar/ContentAnalysisSidebarMeta.vue'
import SidebarPage from '@/components/content-analysis/sidebar/ContentAnalysisSidebarPage.vue'
import SidebarSuggestions from '@/components/content-analysis/sidebar/ContentAnalysisSidebarSuggestions.vue'

export default {
  name: 'ContentAnalysisSidebar',
  components: { SidebarKeywords, SidebarMeta, SidebarPage, SidebarSuggestions }
}
</script>
