import AssessmentStatus from '@/services/content-analysis/assessment/AssessmentStatus'
import { Status } from '@/services/content-analysis/assessment/Status'
import store from '@/store'
import ContentLengthAssessment from '@/services/content-analysis/assessment/content-length-assessment/index'

export default class ContentLengthAssessmentStatusShort extends AssessmentStatus {
  constructor () {
    super(Status.Bad, `The text contains ${store.getters.getWordCount} word${store.getters.getWordCount !== 1 ? 's' : ''}. This is below the recommended minimum of ${ContentLengthAssessment.MINIMUM_WORD_COUNT} words. Add more content that is relevant for the topic.`)
  }
}
