import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import MetaTitleLengthAssessmentStatusMissing
  from '@/services/content-analysis/assessment/meta-title-length-assessment/MetaTitleLengthAssessmentStatusMissing'
import MetaTitleLengthAssessmentStatusShort
  from '@/services/content-analysis/assessment/meta-title-length-assessment/MetaTitleLengthAssessmentStatusShort'
import MetaTitleLengthAssessmentStatusLong
  from '@/services/content-analysis/assessment/meta-title-length-assessment/MetaTitleLengthAssessmentStatusLong'
import MetaTitleLengthAssessmentStatusGood
  from '@/services/content-analysis/assessment/meta-title-length-assessment/MetaTitleLengthAssessmentStatusGood'

export default class MetaTitleLengthAssessment extends Assessment {
  public static readonly MINIMUM_CHARACTER_COUNT: number = 0
  public static readonly TARGET_CHARACTER_COUNT: number = 33
  public static readonly MAXIMUM_CHARACTER_COUNT: number = 55

  assess (): void {
    const metaTitle: string = store.getters.getMetaTitle
    const metaTitleLength: number = metaTitle ? metaTitle.length : 0

    if (metaTitleLength === MetaTitleLengthAssessment.MINIMUM_CHARACTER_COUNT) {
      this.setAssessmentStatus(new MetaTitleLengthAssessmentStatusMissing())
    }
    else if (metaTitleLength > MetaTitleLengthAssessment.MINIMUM_CHARACTER_COUNT && metaTitleLength < MetaTitleLengthAssessment.TARGET_CHARACTER_COUNT) {
      this.setAssessmentStatus(new MetaTitleLengthAssessmentStatusShort())
    }
    else if (metaTitleLength > MetaTitleLengthAssessment.TARGET_CHARACTER_COUNT && metaTitleLength < MetaTitleLengthAssessment.MAXIMUM_CHARACTER_COUNT) {
      this.setAssessmentStatus(new MetaTitleLengthAssessmentStatusGood())
    }
    else if (metaTitleLength > MetaTitleLengthAssessment.MAXIMUM_CHARACTER_COUNT) {
      this.setAssessmentStatus(new MetaTitleLengthAssessmentStatusLong())
    }
  }
}
