import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import ContentLengthAssessmentStatusShort
  from '@/services/content-analysis/assessment/content-length-assessment/ContentLengthAssessmentStatusShort'
import ContentLengthAssessmentStatusGood
  from '@/services/content-analysis/assessment/content-length-assessment/ContentLengthAssessmentStatusGood'

export default class ContentLengthAssessment extends Assessment {
  public static readonly MINIMUM_WORD_COUNT: number = 500

  assess (): void {
    const wordCount: number = store.getters.getWordCount

    if (wordCount < ContentLengthAssessment.MINIMUM_WORD_COUNT) {
      this.setAssessmentStatus(new ContentLengthAssessmentStatusShort())
    }
    else {
      this.setAssessmentStatus(new ContentLengthAssessmentStatusGood())
    }
  }
}
