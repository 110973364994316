




















export default {
  name: 'Home'
}
