import Text from '@/services/content-analysis/content/Text'
import Content from '@/services/content-analysis/content/index'

export default class KeywordMatches extends Text<string[]> {
  private readonly keyword: string
  private readonly densityPercentage: number

  constructor (keyword: string, content: Content) {
    super()

    for (let element of content.getElements()) {
      this.elements.push(element.textContent!.toLowerCase().match(new RegExp(keyword, 'g')) || [])
    }

    this.keyword = keyword
    this.densityPercentage = this.getWordCount() / content.getWordCount() * 100
  }

  public getWords (): string[][] {
    return this.elements
  }

  public getByIndex (index: number): string[] {
    return index in this.elements ? this.elements[index] : []
  }

  public getDensityPercentage (): number {
    return this.densityPercentage
  }

  public getDensityPercentageString (): string {
    return this.getDensityPercentage().toFixed(1)
  }

  public getKeyword (): string {
    return this.keyword
  }
}
