import { Module } from 'vuex'

const UrlModule: Module<any, any> = {
  state: {
    url: ''
  },
  getters: {
    getUrl: state => { return state.url },
    getParsedUrl: state => {
      try {
        return new URL(state.url)
      }
      catch (e) {
        return state.url
      }
    },
    getHostname: (state, getters) => { return getters.getParsedUrl.hostname },
    getProtocol: (state, getters) => { return getters.getParsedUrl.protocol },
    getDomain: (state, getters) => { return `${getters.getProtocol}//${getters.getHostname}` }
  },
  mutations: {
    setUrl: (state, url) => { state.url = url || '' }
  },
  actions: {}
}

export default UrlModule
