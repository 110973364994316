import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import HeaderLevelElementRightOrderAssessmentStatusGood
  from '@/services/content-analysis/assessment/header-level-element-right-order-assessment/HeaderLevelElementRightOrderAssessmentStatusGood'
import HeaderLevelElementRightOrderAssessmentStatusBad
  from '@/services/content-analysis/assessment/header-level-element-right-order-assessment/HeaderLevelElementRightOrderAssessmentStatusBad'

export default class HeaderLevelElementRightOrderAssessment extends Assessment {
  assess (): void {
    if (
      store.getters.getFirstElement.tagName !== 'H1' ||
      store.getters.getHeaders.getHeadersByTagName('H1').length > 1
    ) {
      this.resetAssessmentStatus()
      return
    }

    const headersElements = store.getters.getHeaders.getElements()

    for (let i: number = 1; i < headersElements.length; i++) {
      const currentTagName: string = headersElements[i].tagName
      const currentTagNamePosition: number = parseInt(currentTagName.substring(1, 2))
      const previousTagName: string = headersElements[i - 1].tagName
      const previousTagNamePosition: number = parseInt(previousTagName.substring(1, 2))

      if (currentTagNamePosition >= previousTagNamePosition && Math.abs(currentTagNamePosition - previousTagNamePosition) > 1) {
        this.setAssessmentStatus(new HeaderLevelElementRightOrderAssessmentStatusBad(currentTagName, previousTagName))
        return
      }
    }
    this.setAssessmentStatus(new HeaderLevelElementRightOrderAssessmentStatusGood())
  }
}
