import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import HeaderCountAssessmentStatusLow
  from '@/services/content-analysis/assessment/header-count-assessment/HeaderCountAssessmentStatusLow'

export default class HeaderCountAssessment extends Assessment {
  public static readonly MINIMUM_HEADER_COUNT: number = 1

  assess (): void {
    const headerElements = store.getters.getHeaders.getElements()
    const headerElementCount = headerElements.length

    this.resetAssessmentStatus()

    if (headerElementCount < HeaderCountAssessment.MINIMUM_HEADER_COUNT) {
      this.setAssessmentStatus(new HeaderCountAssessmentStatusLow(headerElementCount))
    }
  }
}
