<template>
  <v-navigation-drawer app v-model="drawer">
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-img class="pb-1" :src="require('../../assets/img/positivedigits-logo.png')" contain></v-img>
          <v-list-item-subtitle>Marketing Tools</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <!-- Start Menu List Items -->
      <v-item-group>
        <v-list-item link :to="{ name: 'Dashboard' }">
          <v-list-item-action>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'ContentAnalysis' }">
          <v-list-item-action>
            <v-icon>mdi-file-edit</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Content Analysis</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-item-group>
      <!-- End Menu List Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SideMenu',
  computed: {
    drawer: {
      get () {
        return this.$store.getters.getDrawer
      },
      set (value) {
        this.$store.commit('setDrawer', value)
      }
    }
  }
}
</script>
