import Text from '@/services/content-analysis/content/Text'
import ContentParser from '@/services/content-analysis/content/ContentParser'

export default class Sentences extends Text<string> {
  constructor (paragraphs: HTMLElement[]) {
    super()

    for (let paragraph of paragraphs) {
      if (!paragraph.textContent) continue

      const sentences = paragraph.textContent!.split(ContentParser.GET_SENTENCE_REGEX())

      this.elements.push(...sentences)
    }
  }

  getTotalWords (): string[] {
    return this.getWords().flat()
  }
}
