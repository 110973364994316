import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import ExternalLinkAssessmentStatusGood
  from '@/services/content-analysis/assessment/external-link-assessment/ExternalLinkAssessmentStatusGood'

export default class ExternalLinkAssessment extends Assessment {
  public static readonly MINIMUM_LINK_COUNT: number = 0

  assess (): void {
    if (store.getters.getExternalLinkCount > ExternalLinkAssessment.MINIMUM_LINK_COUNT) {
      this.setAssessmentStatus(new ExternalLinkAssessmentStatusGood())
    }
    else {
      this.resetAssessmentStatus()
    }
  }
}
