import { Module } from 'vuex'
import KeywordMatches from '@/services/content-analysis/content/KeywordMatches'

const KeywordsModule: Module<any, any> = {
  state: {
    keywords: []
  },
  getters: {
    getKeywords: state => { return state.keywords.map((k: string) => k.toLowerCase().trim()) },
    getKeyword: state => { return state.keywords.join(',') },
    getKeywordMatches: (state, getters) => { return getters.getContent.getKeywordMatches() },
    getKeywordsInFirstParagraph: (state, getters) => { return getters.getKeywordMatches.filter((f: KeywordMatches) => f.getByIndex(0).length > 0) }
  },
  mutations: {
    setKeywords: (state, keywords) => { state.keywords = keywords.filter((k: string) => (new RegExp('\\S', 'g').test(k))) || [] }
  },
  actions: {}
}

export default KeywordsModule
