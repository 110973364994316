import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import ItalicKeywordFirstParagraphAssessmentGood
  from '@/services/content-analysis/assessment/italic-keyword-first-paragraph-assessment/ItalicKeywordFirstParagraphAssessmentGood'
import ItalicKeywordFirstParagraphAssessmentOk
  from '@/services/content-analysis/assessment/italic-keyword-first-paragraph-assessment/ItalicKeywordFirstParagraphAssessmentOk'
import ItalicKeywordFirstParagraphAssessmentBad
  from '@/services/content-analysis/assessment/italic-keyword-first-paragraph-assessment/ItalicKeywordFirstParagraphAssessmentBad'

export default class ItalicKeywordFirstParagraphAssessment extends Assessment {
  assess (): void {
    const italic = store.getters.getFirstItalic
    const keywords = store.getters.getKeywords

    this.resetAssessmentStatus()

    for (let keyword of keywords) {
      const matches: number = italic.map((i: string) => (i.match(new RegExp(keyword, 'g')) || []).length)
        .reduce((a: number, b: number) => a + b, 0)

      if (matches === 1) {
        this.addAssessmentStatus(new ItalicKeywordFirstParagraphAssessmentGood(keyword))
      }
      else if (matches > 1) {
        this.addAssessmentStatus(new ItalicKeywordFirstParagraphAssessmentOk(keyword))
      }
      else {
        this.addAssessmentStatus(new ItalicKeywordFirstParagraphAssessmentBad(keyword))
      }
    }
  }
}
