import IText from '@/services/content-analysis/content/IText'
import ContentParser from '@/services/content-analysis/content/ContentParser'

export default class Text<T extends HTMLElement | string | string[]> implements IText {
  protected elements: T[] = []

  getElements (): T[] {
    return this.elements
  }

  getWords (): string[][] {
    return this.elements.map(e => {
      let words: string[] = []

      if (e instanceof HTMLElement) {
        words = e.textContent!.split(ContentParser.GET_WORD_REGEX())
      }
      else if (typeof e === 'string') {
        words = e.split(ContentParser.GET_WORD_REGEX())
      }

      words = words.map((w: string) => w.toLowerCase())

      return words
    })
  }

  getTotalWords (): string[] {
    return this.getWords().flat()
  }

  getWordCount (): number {
    return this.getTotalWords().length
  }
}
