import Text from '@/services/content-analysis/content/Text'
import ContentParser from '@/services/content-analysis/content/ContentParser'
import HeaderSibling from '@/services/content-analysis/content/HeaderSibling'

export default class Headers extends Text<HTMLElement> {
  private readonly siblings: HeaderSibling[]

  constructor (contentParser: ContentParser) {
    super()

    this.elements = Array.from(contentParser.getBody().querySelectorAll(ContentParser.HEADER_TAGS.join(', ')))

    this.siblings = this.elements.map((e: HTMLElement) => new HeaderSibling(e))
  }

  public getSiblings (): HeaderSibling[] {
    return this.siblings
  }

  public getHeadersByTagName (tagName: string): HTMLElement[] {
    return this.getElements().filter((h: HTMLElement) => h.tagName === tagName)
  }
}
