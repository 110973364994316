import Assessment from '@/services/content-analysis/assessment/Assessment'
import store from '@/store'
import MetaTitleAssessmentStatusGood
  from '@/services/content-analysis/assessment/meta-title-assessment/MetaTitleAssessmentStatusGood'
import MetaTitleAssessmentStatusOk
  from '@/services/content-analysis/assessment/meta-title-assessment/MetaTitleAssessmentStatusOk'

export default class MetaTitleAssessment extends Assessment {
  assess (): void {
    const metaTitle: string = store.getters.getMetaTitle.toLowerCase()
    const keywords: string = store.getters.getKeywords

    this.resetAssessmentStatus()

    for (let keyword of keywords) {
      if (metaTitle.startsWith(keyword)) {
        this.addAssessmentStatus(new MetaTitleAssessmentStatusGood(keyword))
      }
      else if (metaTitle.includes(keyword)) {
        this.addAssessmentStatus(new MetaTitleAssessmentStatusOk(keyword))
      }
    }
  }
}
