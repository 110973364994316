import firebase from 'firebase/compat'
import Vue from 'vue'
import Router from 'vue-router'

import Empty from '@/layouts/Empty.vue'
import Dashboard from '@/layouts/Dashboard.vue'

import Login from '@/views/Login.vue'
import DashboardHome from '@/views/dashboard/Home.vue'
import ContentAnalysis from '@/views/dashboard/ContentAnalysis.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: '/login'
    },
    {
      path: '/',
      redirect: '/login',
      component: Empty,
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    },
    {
      path: '/dashboard',
      component: Dashboard,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'home',
          name: 'Dashboard',
          component: DashboardHome
        },
        {
          path: 'content-analysis',
          name: 'ContentAnalysis',
          component: ContentAnalysis
        }
      ]
    }
  ]
})

/**
 * Authentication Navigation Guard
 */
router.beforeEach((to, from, next) => {
  const user = firebase.auth().currentUser
  const requiresAuth = to.matched.some(r => r.meta.requiresAuth)

  if (requiresAuth && !user) {
    next('login')
  }
  else if (!requiresAuth && user) {
    next('dashboard')
  }
  else {
    next()
  }
})

export default router
