import AssessmentStatus from '@/services/content-analysis/assessment/AssessmentStatus'
import { Status } from '@/services/content-analysis/assessment/Status'
import store from '@/store'
import KeywordMatches from '@/services/content-analysis/content/KeywordMatches'

export default class KeywordDensityAssessmentStatusLow extends AssessmentStatus {
  constructor (keywordMatches: KeywordMatches) {
    super(Status.Bad, `The keyword density for keyword "${keywordMatches.getKeyword()}" is ${keywordMatches.getDensityPercentageString()}%, which is too low; the focus keyword was found ${keywordMatches.getWordCount()} time${keywordMatches.getWordCount() !== 1 ? 's' : ''}.`)
  }
}
